body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 16px;
  font-size: 16px;
  // background-image: linear-gradient(194deg, #d5d5d5f7 13%, rgba(122, 200, 255, 0.5) 4%), linear-gradient(136deg, rgb(0 99 26 / 7%) 39%, rgb(31 150 160 / 86%) 18%), linear-gradient(113deg, rgb(96 179 129) 40%, rgb(95 149 34 / 97%) 54%), linear-gradient(50deg, rgb(33 150 78) 3%, rgb(33 83 75 / 90%) 3%);
  background-image: linear-gradient(to right, #b6fbff, #83a4d4);
}

.app-header {
  line-height: 32px;
  margin: 0 0 12px 0;
  color: #fff;
  margin-bottom: 40px;
  // text-shadow: 3px 3px 0px rgb(0, 0, 0);
}