.search {
  min-height: calc(100vh - 85px);
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #0000002a;
    padding-left: 15px;
  }

  .image {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    justify-content: space-around;
    min-height: 600px;

    img {
      margin-bottom: 10px;
      transition: transform .2s linear;
      border-radius: 10px;

      &:hover {
        transform: scale(1.3);
      }
    }
  }

  .search-bar {
    width: 800px;
    display: flex;

    button {
      width: 100px;
      margin-left: 10px;
      border: none;
      background-image: linear-gradient(to right, rgb(33 37 41), rgb(33 37 41));
      color: #fff;
      padding: 16px;
      border-radius: 10px;
      box-shadow: 2px 2px 10px #0000002a;

    }
  }

  .hot {
    width: 800px;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #6b6b6b;
    font-size: .8rem;


    .key {
      margin-left: 5px;
      border-radius: 10px;
      padding: 5px 10px;
      background: #fac116;
      color: #212529;
      cursor: pointer;

      &:first-of-type {
        margin-left: 10px;
      }
    }
  }
}

.loading {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: 300px 0;


  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}